import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router,  Route, Routes,Link } from 'react-router-dom';
import { NavLink } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import  './HomePage.css'; // Import the CSS file
import GeocodeModal from './GeocodeModal';
import useLocation from './useLocation';
import BusinessOwnerSection from './sendemail';
// Import the background image if you are not using a public URL
// import backgroundImage from './path-to-your-image/image-name.jpg';

const HomePage = () => {
  const {locationElement,latitude,longitude} = useLocation();
  const [showModal, setShowModal] = useState(false);

  const handleShowModal = () => setShowModal(true);
  const handleCloseModal = () => setShowModal(false);
  const baseUrl = process.env.NODE_ENV === 'production' ? 'https://iezloc.com' : 'http://localhost';
  const baseUrlPort = process.env.NODE_ENV === 'production' ? 'https://iezloc.com' : 'http://localhost:3000';
  const [ppsearchResults, setPpSearchResults] = useState([]);
  const [spsearchResults, setSpSearchResults] = useState([]);
  const handleRequst = async (type, name, rating, user_ratings_total, priceopt, sortby,curpage) => {
 
    const query = `${baseUrl}/productsearch?type=${encodeURIComponent(type)}&name=${encodeURIComponent(name)}` +
      `&rating=${rating}&user_ratings_total=${user_ratings_total}&priceopt=${priceopt}` +
      `&sortby=${encodeURIComponent(sortby)}`+ 
      `&lat=${encodeURIComponent(latitude)}&lon=${encodeURIComponent(longitude)}`+ 
      `&page=${encodeURIComponent(curpage)}`;
  
  
    try {
      const response = await fetch(query);
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      const data = await response.json();
      const firstEightItems = data.slice(0, 5);
      setPpSearchResults(firstEightItems); // Assuming the response is an array of objects

    } catch (error) {
      console.error('Failed to fetch search results:', error);
    }
  };

  const sphandleRequst = async (type, name, rating, user_ratings_total, sortby,curpage) => {

    const query = `${baseUrl}/storesearch?type=${encodeURIComponent(type)}&name=${encodeURIComponent(name)}` +
      `&rating=${rating}&user_ratings_total=${user_ratings_total}` +
      `&sortby=${encodeURIComponent(sortby)}`+
      `&lat=${encodeURIComponent(latitude)}&lon=${encodeURIComponent(longitude)}`+ 
      `&page=${encodeURIComponent(curpage)}`;


    try {
      const response = await fetch(query);
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      const data = await response.json();
      const firstEightItems = data.slice(0, 5);
      setSpSearchResults(firstEightItems); // Assuming the response is an array of objects
    } catch (error) {
      console.error('Failed to fetch search results:', error);
    }
  };
  useEffect(() => {
  
    handleRequst('all', 'all', '=-1', '=-1', 'BestMatch','1');
    sphandleRequst('all', 'all', '=-1', '=-1', 'BestMatch','1')
    // handleSearch();
  }, []); // The empty array means this effect runs only once after initial render

  return (
    <>
      <div className="hpTopBanner">
      <Helmet>
      <title>find best offerings and places in ottawa</title>
      <meta name="description"
      content="Discover the best offerings and places  in Ottawa with our comprehensive guide.
       We offer different ranking methods by distance, rating, and reviews.
       You can also search by keywords, place name, or street."/>
      <link rel="canonical" href={`${baseUrlPort}`} />
      </Helmet>

      <span className="hpLogo">Iezloc</span>
      <span className="hplocation" onClick={handleShowModal}>
        {locationElement}</span>
        <GeocodeModal show={showModal} handleClose={handleCloseModal} currentUrl='/' />
        <ul className="hpNavigationMenu">
      
          <li><NavLink to="/"  className={({ isActive }) => (isActive ? 'activeLink' : '')} >Home</NavLink></li>
          <li><NavLink to="/place"  className={({ isActive }) => (isActive ? 'activeLink' : '')} >Place</NavLink></li>
          <li><NavLink to="/about-us" className={({ isActive }) => (isActive ? 'activeLink' : '')}>About us</NavLink></li>
          <li>Contact</li>
        </ul>
      </div>
      <div className="auContainer">

        <section id="welcome">
          <h2 className="large-heading">Make Local Better with</h2>
          <h2 className="large-heading"> Iezloc Services</h2>
          <p className="highlight-center">Over 50,000 places listed,Discover the <span className="highlight">best places</span> and <span className="highlight">lowest prices</span> in ottawa</p>
          </section>
          <section>
        <BusinessOwnerSection />
        </section>
      </div>
      <div className="hpBottomBanner">
        <span className="hpCopyRight">Copyright © iezloc 2024</span>
        <span className="hpContact">info@iezloc.com</span>
        <Link to="/privacy-policy" className="hpPrivacyPolicy">Privacy Policy and Disclaimer</Link>
      </div>
      </>
  );
}
function renderStars2(rating) {
  const fullStars = Math.floor(rating);
  const halfStars = Math.ceil(rating - fullStars);
  const emptyStars = 5 - fullStars - halfStars;

  return (
    <>
      {[...Array(fullStars)].map((_, i) => <i key={`full-${i}`} className="fas fa-star"></i>)}
      {[...Array(halfStars)].map((_, i) => <i key={`half-${i}`} className="fas fa-star-half-alt"></i>)}
      {[...Array(emptyStars)].map((_, i) => <i key={`empty-${i}`} className="far fa-star"></i>)}
    </>
  );
}

export default HomePage;

