import React from 'react';
import { BrowserRouter as Router,  Route, Routes,Link } from 'react-router-dom';
import { NavLink } from 'react-router-dom';
import './HomePage.css'; // Import the CSS file
import './NotFoundPage.css'; // Import the CSS file


function notfoundpage() {
  return (
    <>
      <div className="hpTopBanner">
        <span className="hpLogo">Iezloc</span>
        <ul className="hpNavigationMenu">
          <li><NavLink to="/"  className={({ isActive }) => (isActive ? 'activeLink' : '')}>Home</NavLink></li>
          <li><NavLink to="/" className={({ isActive }) => (isActive ? 'activeLink' : '')}>Home</NavLink></li>
          <li><NavLink to="/deal" className={({ isActive }) => (isActive ? 'activeLink' : '')} >Deal</NavLink></li>
          <li><NavLink to="/offering" className={({ isActive }) => (isActive ? 'activeLink' : '')} >Offering</NavLink></li>
          <li><NavLink to="/place"  className={({ isActive }) => (isActive ? 'activeLink' : '')} >Place</NavLink></li>
          <li><Link to="https://chat.openai.com/g/g-0bCn0rUiB-ottawa-store-explorer">Gptstore</Link></li> 
          <li><NavLink to="/about-us" className={({ isActive }) => (isActive ? 'activeLink' : '')}>About us</NavLink></li>
          <li>Contact</li>
        </ul>
      </div>
      <div className="nfPageContainer">
        <h1>Uh-oh...</h1>
        <p>This page could not be found.</p>
    </div>
      <div className="hpBottomBanner">
        <span className="hpCopyRight">Copyright © iezloc 2024</span>
        <Link to="/privacy-policy" className="hpPrivacyPolicy">Privacy Policy</Link>
        {/* <span className="privacy-policy">Privacy Policy</span> */}
        {/* <a href="/privacy-policy" className="privacy-policy">Privacy Policy</a> */}
      </div>
    </>
  );
}

export default notfoundpage;

