import React from 'react';
import { BrowserRouter as Router,  Route, Routes,Link } from 'react-router-dom';
import { NavLink } from 'react-router-dom';
import './HomePage.css'; // Import the CSS file
import './PrivacyPolicy.css'; // Import the CSS file


function PrivacyPolicy() {
  return (
    <>
      <div className="hpTopBanner">
        <span className="hpLogo">Iezloc</span>
        <ul className="hpNavigationMenu">
          <li><NavLink to="/"  className={({ isActive }) => (isActive ? 'activeLink' : '')}>Home</NavLink></li>
          <li><NavLink to="/place"  className={({ isActive }) => (isActive ? 'activeLink' : '')} >Place</NavLink></li>
          <li><NavLink to="/about-us" className={({ isActive }) => (isActive ? 'activeLink' : '')}>About us</NavLink></li>
          <li>Contact</li>
        </ul>
      </div>
      <div className="ppPrivacyContainer">
        <h1>Privacy Policy</h1>
        <p>Last updated: 2024.02.14</p>
        <p>This Privacy Notice applies to all the products, services, gpts(ChatGPT-4 store), websites and apps offered by IEzLoc,We refer to those products, services, gpts(ChatGPT-4 store), websites, and apps collectively as the "services" in this notice.</p>
        <p>one of our main priorities is the privacy of our visitors. This Privacy Policy document contains types of information that is collected and recorded by services and how we use it.</p>

        <h2>Information Collection And Use</h2>
        <p>We collect several different types of information for various purposes to provide and improve our service to you.</p>

        <h3>Personal Data</h3>
        <p>While using our Service, we may ask you to provide us with certain personally identifiable information that can be used to contact or identify you ("Personal Data"). Personally identifiable information may include, but is not limited to:</p>
        <ul>
          <li>Email address</li>
          <li>First name and last name</li>
          <li>Phone number</li>
          <li>Address, State, Province, ZIP/Postal code, City</li>
        </ul>

        <h3>Log Data</h3>
        <p>We also collect information how the Service is accessed and used ("Log Data"). This Log Data may include information such as your computer's Internet Protocol (IP) address, browser type, browser version, the pages of our Service that you visit, the time and date of your visit, the time spent on those pages, and other statistics.</p>

        <h2>Use of Data</h2>
        <p>services uses the collected data for various purposes:</p>
        <ul>
          <li>To provide and maintain our Service</li>
          <li>To notify you about changes to our Service</li>
          <li>To allow you to participate in interactive features of our Service when you choose to do so</li>
          <li>To provide customer support</li>
          <li>To gather analysis or valuable information so that we can improve our Service</li>
          <li>To monitor the usage of our Service</li>
        </ul>
        <h1>Disclaimer</h1>
        <p>Last updated: 2024.06.08</p>
    <h2>General Information</h2>
    
    <p>The information provided on this platform is for general informational purposes only. All information on the site is provided in good faith; however, we make no representation or warranty of any kind, express or implied, regarding the accuracy, adequacy, validity, reliability, availability, or completeness of any information on the site.</p>

    <h2>Not Legal or Professional Advice</h2>
    <p>The content on this platform does not constitute legal, financial, or other professional advice. If you require such advice, you should consult with a professional in the relevant field.</p>

    <h2>External Links</h2>
    <p>This platform may contain (or you may be sent through the site) links to other websites or content belonging to or originating from third parties or links to websites and features in banners or other advertising. Such external links are not investigated, monitored, or checked for accuracy, adequacy, validity, reliability, availability, or completeness by us.</p>

    {/* <h2>Affiliates Disclaimer</h2>
    <p>This platform may contain links to affiliate websites, and we receive an affiliate commission for any purchases made by you on the affiliate website using such links.</p> */}

    <h2>User Responsibility</h2>
    <p>Any actions you take upon the information you find on this platform are strictly at your own risk. We will not be liable for any losses and/or damages in connection with the use of our platform.</p>

    <h2>Content Accuracy</h2>
    <p>We strive to ensure that the information on this platform is current and accurate. However, there may be delays, omissions, or inaccuracies in the information contained on this site. We do not warrant that the site will be uninterrupted, free of viruses, or other harmful components.</p>

    <h2>Changes to Disclaimer</h2>
    <p>We reserve the right to update, amend, or make any changes to this document at our discretion. Any changes will be prominently posted here.</p>

    <h2>Contact Us</h2>
    <p>If you have any questions about this disclaimer, you can contact us at [ info@iezloc.com ].</p>
      </div>
  
      <div className="hpBottomBanner">
        <span className="hpCopyRight">Copyright © iezloc 2024</span>
        <Link to="/privacy-policy" className="hpPrivacyPolicy">Privacy Policy</Link>
        {/* <span className="privacy-policy">Privacy Policy</span> */}
        {/* <a href="/privacy-policy" className="privacy-policy">Privacy Policy</a> */}
      </div>
    </>
  );
}

export default PrivacyPolicy;

