import React from 'react';
import { BrowserRouter as Router, Route, Routes, Link } from 'react-router-dom';
import { NavLink } from 'react-router-dom';
import './HomePage.css'; // Import the CSS file
import './AboutUs.css'; // Import the CSS file


function AboutUs() {
  return (
    <>
      <div className="hpTopBanner">
        <span className="hpLogo">Iezloc</span>
        <ul className="hpNavigationMenu">
          {/* <li>Home</li> */}
          <li><NavLink to="/" className={({ isActive }) => (isActive ? 'activeLink' : '')}>Home</NavLink></li>
          <li><NavLink to="/place"  className={({ isActive }) => (isActive ? 'activeLink' : '')} >Place</NavLink></li>
          <li><NavLink to="/about-us" className={({ isActive }) => (isActive ? 'activeLink' : '')}>About us</NavLink></li>
          <li>Contact</li>
        </ul>
      </div>
      <div className="auContainer">
        <section id="welcome">
          <h2>Welcome to Iezloc</h2>
          <p>Welcome to Iezloc, where navigating the world around you becomes effortless and intuitive. At the heart of our mission lies a simple yet profound belief: discovering local stores, products, and services should be a seamless experience for everyone.</p>
          <p>Founded on the principles of innovation, ease, and connectivity, Iezloc is dedicated to redefining how individuals and businesses interact with their immediate geographical surroundings. Our platform leverages cutting-edge technology to provide accurate, up-to-date, and accessible location-based services tailored to your needs.</p>
        </section>
        <section id="our-mission">
          <h2>Our Mission</h2>
          <p>Whether you're a local seeking new experiences or a traveler exploring unfamiliar territories, Iezloc guides you to your desired destinations with precision and ease. Our solutions are designed to bridge the gap between the digital and physical realms, making local discovery a joy rather than a challenge.</p>
          <p>Our team,Located in Ottawa,Ontario,Canada, a blend of tech enthusiasts and visionaries, is committed to delivering a user-friendly interface and innovative features that enhance your navigational experience. From finding the nearest café with the best coffee to uncovering hidden gems in your neighborhood, Iezloc is your go-to guide.</p>
        </section>
      </div>
      <div className="hpBottomBanner">
        <span className="hpCopyRight">Copyright © iezloc 2024</span>
        <span className="hpContact">info@iezloc.com</span>
        <Link to="/privacy-policy" className="hpPrivacyPolicy">Privacy Policy and Disclaimer</Link>
        {/* <span className="privacy-policy">Privacy Policy</span> */}
        {/* <a href="/privacy-policy" className="privacy-policy">Privacy Policy</a> */}
      </div>
    </>
  );
}

export default AboutUs;

