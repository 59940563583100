import React, { useState } from 'react';
import './sendemail.css'; // Import the CSS file
// import '@fortawesome/fontawesome-free/css/all.min.css';

const BusinessOwnerSection = () => {
  const [email, setEmail] = useState('');

  const handleEmailChange = (event) => {
    setEmail(event.target.value);
  };

  const handleButtonClick = () => {
    if (email) {
      window.location.href = `mailto:info@iezloc.com?subject=Newsletter%20Subscription&body=Please%20subscribe%20me%20to%20the%20newsletter.%20My%20email%20is:%20${email}`;
    } else {
      alert('Please enter a valid email address.');
    }
  };

  return (
    <section className="business-owner">
      <h2>Join the Revolution</h2>
      <p>Empower your business with our comprehensive listings.</p>
      <p>Engage your audience with detailed profiles, social media integration, and more.</p>
      <div className="newsletter-signup">
        <i className="fas fa-envelope"></i>
        <input
          type="email"
          value={email}
          onChange={handleEmailChange}
          placeholder="Email Address"
          required
        />
        <button onClick={handleButtonClick}>Contact Us</button>
      </div>
      {/* <style jsx>{`
        .business-owner {
          background: linear-gradient(to right, #ff5f6d, #ffc371);
          color: white;
          padding: 40px;
          border-radius: 8px;
          text-align: left;
          max-width: 800px;
          margin: 0 auto;
        }
        .business-owner h2 {
          font-size: 2em;
          font-weight: bold;
          margin-bottom: 20px;
        }
        .business-owner p {
          font-size: 1.2em;
          margin-bottom: 10px;
        }
        .newsletter-signup {
          display: flex;
          align-items: center;
          margin-top: 20px;
        }
        .newsletter-signup input[type="email"] {
          flex: 1;
          padding: 10px;
          font-size: 1em;
          border: 1px solid #ccc;
          border-radius: 4px 0 0 4px;
          outline: none;
        }
        .newsletter-signup button {
          padding: 10px 20px;
          font-size: 1em;
          font-weight: bold;
          color: white;
          background-color: #0056b3;
          border: none;
          border-radius: 0 4px 4px 0;
          cursor: pointer;
          transition: background-color 0.3s;
        }
        .newsletter-signup button:hover {
          background-color: #004080;
        }
        .fa-envelope {
          margin-right: 10px;
        }
      `}</style> */}
    </section>
  );
};

export default BusinessOwnerSection;
