import React, { useState, useEffect } from 'react';
import './GeocodeModal.css';

const GeocodeModal = ({ show, handleClose,currentUrl }) => {
  const [query, setQuery] = useState('');
  const [results, setResults] = useState([]);
  const [crturl, setCrturl] = useState(currentUrl);

  useEffect(() => {
    setCrturl(currentUrl); // Update crturl whenever currentUrl changes
  }, [currentUrl]);
  
  useEffect(() => {
    if (query) {
      searchAddress(query);
    } else {
      setResults([]);
    }
  }, [query]);

    //https://nominatim.openstreetmap.org/search?addressdetails=1&q=24+cote+des+neiges+ontario&format=jsonv2&limit=10
  const searchAddress = async (query) => {
    const predefinedLocation = 'Ottawa, Ontario';
    const fullQuery = `${query} ${predefinedLocation}`;
    const response = await fetch(`https://nominatim.openstreetmap.org/search?addressdetails=1&q=${encodeURIComponent(fullQuery)}&format=jsonv2&limit=10`);
    const data = await response.json();
    setResults(data);
  };

  const handleSelectAddress = (address) => {
    const latitude = address.lat;
    const longitude = address.lon;
    const formattedAddress =formatAddress(address);
    // const formattedAddress = `${address.address.house_number || ''} ${address.address.road || ''}, ${address.address.city || address.address.town || address.address.village || ''}, ${address.address.state || ''}, ${address.address.postcode || ''}`;

    const locationData = {
      latitude,
      longitude,
      address: formattedAddress,
    };

    localStorage.setItem('userLocation', JSON.stringify(locationData));
    handleClose();
    window.location.href = crturl;
  };

  const formatAddress = (address) => {
    const houseNumber = address.address?.house_number || '';
    const road = address.address?.road || '';
    const city = address.address?.city || address.address?.town || address.address?.village || '';
    const state = address.address?.state || '';
    const postcode = address.address?.postcode || '';
    return `${houseNumber} ${road}, ${city}, ${state}, ${postcode}`;
  };

  const formatAddress2 = (address) => {
    const houseNumber = address.address?.house_number || '';
    const road = address.address?.road || '';
    const city = address.address?.city || address.address?.town || address.address?.village || '';
    const state = address.address?.state || '';
    const postcode = address.address?.postcode || '';
    return {
        resultAddress: `${houseNumber} ${road}`,
        resultDetails: `${city}, ${state}, ${postcode}`,
      };
  };

  if (!show) {
    return null;
  }



  return (
    <div className="modal-overlay">
      <div className="modal-content">
        <button className="modal-close" onClick={handleClose}>
          &times;
        </button>
        <h2>Choose Location</h2>
        <div className="input-container">
          <input
            type="text"
            placeholder="Enter address keywords"
            value={query}
            onChange={(e) => setQuery(e.target.value)}
          />
          <button className="clear-button" onClick={() => setQuery('')}>
            Clear
          </button>
        </div>
        <ul className="results-list">
        {results.map((result) => {
            const { resultAddress, resultDetails } = formatAddress2(result);
            return (
              <li key={result.place_id} onClick={() => handleSelectAddress(result)}>
                <span className="result-icon">📍</span>
                <div className="result-text">
                  <div className="result-address">{resultAddress}</div>
                  <div className="result-details">{resultDetails}</div>
                </div>
              </li>
            );
          })}
        </ul>
      </div>
    </div>
  );
};

export default GeocodeModal;
