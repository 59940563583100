// Pagination.jsx
import React from 'react';
import { Helmet } from 'react-helmet';
import './Paginationhref.css';

export const Pagination = ({ totalItems, itemsPerPage, basePath, curPage }) => {
  const pageCount = Math.ceil(totalItems / itemsPerPage);
  const pageNumbers = Array.from({ length: pageCount }, (_, i) => i + 1);

  const generateHref2 = (pageNumber) => {
    return `${basePath}&page=${pageNumber}`;
  };
  const generateHref = (newPage) => {
    // Create a URL object from the provided URL string
    const url = new URL(basePath);
    
    // Update the 'page' query parameter
    url.searchParams.set('page', newPage);
  
    // Return the new URL as a string
    return url.toString();
  };

  return (
    <nav className="pagination">
      <ul>
        <li className={`page-item ${curPage === 1 ? 'disabled' : ''}`}>
          <a href={curPage > 1 ? generateHref(curPage - 1) : '#'} aria-disabled={curPage === 1}>
            &lt;
          </a>
        </li>

        {pageNumbers.map(number => (
          <li key={number} className={`page-item ${number === curPage ? 'active' : ''}`}>
            <a href={generateHref(number)}>
              {number}
            </a>
          </li>
        ))}

        <li className={`page-item ${curPage === pageCount ? 'disabled' : ''}`}>
          <a href={curPage < pageCount ? generateHref(curPage + 1) : '#'} aria-disabled={curPage === pageCount}>
            &gt;
          </a>
        </li>
      </ul>
    </nav>
  );
};


export const generateCanonicalLink = ({basePath, curpage}) => {
  const generateHref = (newPage) => {
    const url = new URL(basePath);
    url.searchParams.set('page', newPage);
    return url.toString();
  };

  const canonicalUrl = generateHref(curpage);

  return (
    <Helmet>
      <link rel="canonical" href={canonicalUrl} />
    </Helmet>
  );
};