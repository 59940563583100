import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes, Link } from 'react-router-dom';
import { NavLink } from 'react-router-dom';
import './HomePage.css'; // Import the CSS file
import './StorePage.css'; // Import the CSS file
import StoreCategoryType from './StoreCategoryType';
import { parseUrlParams } from './StorePageUtils'
import { Pagination,generateCanonicalLink } from './Paginationhref';
import useLocation from './useLocation';
import CategorySelect from './CategorySelect';
import { categoryIcons } from './CategoryIcons';
import GeocodeModal from './GeocodeModal';

function StorePage() {
  const [isSortMenuVisible, setIsSortMenuVisible] = useState(false);
  const [sortOption, setSortOption] = useState('BestMatch');
  const baseUrl = process.env.NODE_ENV === 'production' ? 'https://iezloc.com' : 'http://localhost';
  const baseUrlPort = process.env.NODE_ENV === 'production' ? 'https://iezloc.com' : 'http://localhost:3000';
  const [CateTypelinks, setCateTypelinks] = useState([]);
  const [CateTypeslinks, setCateTypeslinks] = useState([]);
  const [isLoaded, setIsLoaded] = useState(false);
  const [basePath, setBasePath] = useState(`${baseUrlPort}/store?type=all&name=all&rating==-1&user_ratings_total==-1&sortby=BestMatch`);
  const [curpage, setCurpage] =useState(1);
  ///store?type=all&name=all&rating==-1&user_ratings_total==-1&sortby=BestMatch
  // Assuming you have a state to keep track of items
  const totalItems = 180; // for example, if you have 180 items
  const itemsPerPage = 30; // assuming 10 items per page
 

  const handleSortButtonClick = () => {
    // console.log('Sort button clicked. Current state is:', !isSortMenuVisible); // This should log the expected state chang
    setIsSortMenuVisible(!isSortMenuVisible);
  };

  const handleSortOptionChange = (option) => {
    setSortOption(option);
    setIsSortMenuVisible(false);
    setCategoryNav(option);
    triggerSearch(selectedType, searchName, option)
  };
  const [selectedCategory, setSelectedCategory] = useState('all');
  const [selectedType, setSelectedType] = useState('all');

  const categories = ['all', ...Object.keys(StoreCategoryType)];
  const types = selectedCategory === 'all' ? [] : StoreCategoryType[selectedCategory];

  function findCategoryByKeyValue(obj, value) {
    let foundKey = null;
    // Iterate over the object
    Object.entries(obj).forEach(([key, values]) => {
      // Check if the value exists in the array
      if (values.includes(value)) {
        foundKey = key;
      }
    });
    return foundKey;
  }
  // Handle the key down event
  const handleKeyDown = (event) => {
    // Check if the Enter key was pressed
    if (event.key === 'Enter') {
      handleSearch();
    }
  };
  const handleCategoryChange = (event) => {
    const newCategory = event.target.value;
    setSelectedCategory(newCategory);
    // If 'all' is not selected, set the first type of the new category as selected
    if (newCategory !== 'all' && StoreCategoryType[newCategory].length > 0) {
      setSelectedType(StoreCategoryType[newCategory][0]);
    } else {
      setSelectedType('all');
    }
  };

  const handleTypeChange = (event) => {
    setSelectedType(event.target.value);
  };

  const [searchName, setSearchName] = useState('all');
  // const [sortBy, setSortBy] = useState('BestMatch');

  const handleSearchInputChange = (event) => {
    setSearchName(event.target.value || 'all');
  };


  const handleSearch = () => {
    triggerSearch(selectedType, searchName, sortOption);
  };
  const [searchResults, setSearchResults] = useState([]);

  // Function to trigger the search request
  const triggerSearch = (selectedType, searchName, sortBy) => {
    //console.log(query); // For testing purposes
    // Prepare your search parameters here
    const type = selectedType !== 'all' ? selectedType : 'all';
    const name = searchName.trim() !== '' ? searchName : 'all';
    const sortby = sortBy;
    const rating = '=-1';
    const user_ratings_total = '=-1';

    // Call handleSearch with the prepared parameters
    const query = `${baseUrlPort}/store?type=${encodeURIComponent(type)}&name=${encodeURIComponent(name)}` +
      `&rating=${rating}&user_ratings_total=${user_ratings_total}` +
      `&sortby=${encodeURIComponent(sortby)}` ;
    window.location.href = query;
    // handleRequst(type, name, sortby,rating,user_ratings_total);
    // fetch(query) to make the actual request, then process the response
  };
  const {locationElement,latitude,longitude} = useLocation();
  // Function to perform the actual search
  const handleRequst = async (type, name, rating, user_ratings_total, sortby,curpage) => {

    const query = `${baseUrl}/storesearch?type=${encodeURIComponent(type)}&name=${encodeURIComponent(name)}` +
      `&rating=${rating}&user_ratings_total=${user_ratings_total}` +
      `&sortby=${encodeURIComponent(sortby)}`+
      `&lat=${encodeURIComponent(latitude)}&lon=${encodeURIComponent(longitude)}`+ 
      `&page=${encodeURIComponent(curpage)}`;
    const pageurl= `${baseUrlPort}/store?type=${encodeURIComponent(type)}&name=${encodeURIComponent(name)}` +
    `&rating=${rating}&user_ratings_total=${user_ratings_total}` +
    `&sortby=${encodeURIComponent(sortby)}`;
    setBasePath(pageurl);
    setCurpage(+curpage);
    try {
      const response = await fetch(query);
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      const data = await response.json();
      setSearchResults(data); // Assuming the response is an array of objects
    } catch (error) {
      console.error('Failed to fetch search results:', error);
    }
  };
  // useEffect hook to run once the component is mounted
  const setCategoryNav = (sortOption) => {
    // const baseurl = "http://localhost:3000/store";
    const generatedLinks = Object.entries(StoreCategoryType).map(([category, types]) => {
      const firstType = types[0];
      const url = `${baseUrlPort}/store?type=${firstType}&name=all&rating==-1&user_ratings_total==-1&sortby=${sortOption}`;
      return { name: category, url: url,category: category };
    });
    setCateTypelinks(generatedLinks);
  };
  // init left side full types list:
  const setCategoryTypesNav = (selectedCategory,sortOption) => {
    // const baseurl = "http://localhost:3000/store";
        // Start with the selected category as the first item
    const generatedLinks = [{
      name: selectedCategory,
      url: "#",  // Placeholder URL for the category
    }];
    if (selectedCategory === "all") {
      return ;
    }
    const types = StoreCategoryType[selectedCategory];

    if (!types) {
      console.error(`The category '${selectedCategory}' does not exist.`);
      return;
    }

    // const generatedLinks = types.map(type => {
      generatedLinks.push(...types.map(type => {
      const url = `${baseUrlPort}/store?type=${type}&name=all&rating==-1&user_ratings_total==-1&sortby=${sortOption}`;
      return { name: type, url ,type: type};
    }));
  
    setCateTypeslinks(generatedLinks);
  };
  useEffect(() => {
    //for generate category-first-type link
    
    // for loading results
    const { para_type, para_name, para_rating, para_reviews, para_sort_by,para_page} = parseUrlParams();

    const category = para_type !== "all" ? findCategoryByKeyValue(StoreCategoryType, para_type) : "all";
    setSelectedCategory(category);
    setCategoryTypesNav(category,para_sort_by);
    setSelectedType(para_type);
    setSearchName(para_name);
    setSortOption(para_sort_by);
    setCategoryNav(para_sort_by);
    handleRequst(para_type, para_name, para_rating, para_reviews, para_sort_by,para_page).then(() => {
      setIsLoaded(true);
    });;
    // handleSearch();
  }, []); // The empty array means this effect runs only once after initial render

  const { para_type} = parseUrlParams();
  const category = para_type !== "all" ? findCategoryByKeyValue(StoreCategoryType, para_type) : "all";
  const [showModal, setShowModal] = useState(false);

  const handleShowModal = () => setShowModal(true);
  const handleCloseModal = () => setShowModal(false);

  return (
    <>
      <div className="hpTopBanner">
        <span className="hpLogo">Iezloc</span>
          <span className="hplocation" onClick={handleShowModal}>
        {locationElement}</span>
        <GeocodeModal show={showModal} handleClose={handleCloseModal} currentUrl={basePath} />
        <ul className="hpNavigationMenu">
          {/* <li>Home</li> */}
          <li><NavLink to="/" className={({ isActive }) => (isActive ? 'activeLink' : '')}>Home</NavLink></li>
          <li><NavLink to="/deal"  className={({ isActive }) => (isActive ? 'activeLink' : '')} >Deal</NavLink></li>
          <li><NavLink to="/offering" className={({ isActive }) => (isActive ? 'activeLink' : '')} >Offering</NavLink></li>
          {/* <li>Store</li> */}
          <li><NavLink to="/place" className={({ isActive }) => (isActive ? 'activeLink' : '')} >Place</NavLink></li>
          {/* <li>Gptstore</li> */}
          <li><Link to="https://chat.openai.com/g/g-0bCn0rUiB-ottawa-store-explorer">Gptstore</Link></li>
          {/* <li>About us</li> */}
          <li><NavLink to="/about-us" className={({ isActive }) => (isActive ? 'activeLink' : '')}>About us</NavLink></li>
          <li>Contact</li>
        </ul>
      </div>
      <div className="spContainer">
        <div className="spNavRow1">
        <CategorySelect
        selectedCategory={selectedCategory}
        handleCategoryChange={handleCategoryChange}
        categories={categories}
        cateClassname ="spCategory"
      />
          {/* <select
            value={selectedCategory}
            onChange={handleCategoryChange}
            className="spCategory"
          >
            {categories.map((category, index) => (
              <option key={index} value={category}>
                {category !== 'all' ? category : 'All'} 
              </option>
            ))}
          </select> */}
          <select
            value={selectedType}
            onChange={handleTypeChange}
            className="spType"
            disabled={selectedCategory === 'all'} // Disable if 'All' categories are selected
          >
            {/* Render the 'All' option only if the selected category is 'all' */}
            {selectedCategory === 'all' && <option value="all">All</option>}
            {types.map((type, index) => (
              <option key={index} value={type}>
                {type}
              </option>
            ))}
          </select>
          <div className="spInputContainer">
            <input
              className="spInputWords"
              type="text"
              value={searchName === 'all' ? '' : searchName}
              onChange={handleSearchInputChange}
              onKeyDown={handleKeyDown}
              placeholder="Search address ,name"
            />
            <button className="spInputButton" onClick={handleSearch} tabIndex="0">Search</button>
          </div>
        </div>
        <div className="spNavRow2">
          <button className="spSortButton" onClick={handleSortButtonClick}>
            Sort by <span className="spSortText">{sortOption.replace(/([A-Z])/g, ' $1').trim()}</span>
            <span className="spSortArrow">^</span>
          </button>
          {isSortMenuVisible && (
            <div className={`spSortMenu ${isSortMenuVisible ? 'visible' : ''}`}>
              <div onClick={() => handleSortOptionChange('BestMatch')}>Best Match</div>
              <div onClick={() => handleSortOptionChange('rating_asc')}>Rating: low to high</div>
              <div onClick={() => handleSortOptionChange('rating_desc')}>Rating: high to low</div>
              <div onClick={() => handleSortOptionChange('user_ratings_total_asc')}>Reviews Total: low to high</div>
              <div onClick={() => handleSortOptionChange('user_ratings_total_desc')}>Reviews Total: high to low</div>
              <div onClick={() => handleSortOptionChange('distance_near_far')}>Distance : near to far</div>
              {/* <div onClick={() => handleSortOptionChange('distance_far_near')}>Distance : far to near</div> */}
            </div>
          )}
        </div>
        <div className='spNavRow3'>
          {CateTypelinks.map((link, index) => (
            <a key={index} href={link.url} className={`spNavCategoryLink ${link.category === category ? 'sphighlight' : ''}`}>
               <i className={categoryIcons[link.name]} style={{ marginRight: '8px' }}></i>
              {link.name}
            </a>
          ))}
        </div>
        <div className="spTwoColGrid">
          <div className="spLeftNav1">
            <ul className='spLeftTypes'>
              {CateTypeslinks.map((link, index) => (
                <li key={index} className= {`spLeftTypeslist ${link.type === para_type ? 'sphighlight' : ''}`}>
                  <a className='spLeftTypeslink' href={link.url}>
                    {link.name}
                  </a>
                </li>
              ))}
            </ul>
          </div>

          <div className="spResultsGrid">
            {searchResults.map((store, index) => (
              <div className="spStoreGrid" key={index}>
                {/* <div className="spStoreImage">
                  <img src={`${store.iconMaskBaseUri}.svg`} alt="" style={{ backgroundColor: store.iconBackgroundColor }} />
                </div> */}
                <div className="spStoreInfo">
                  {/* <h2 className="spStoreName">{store.display_name}</h2> */}
                  <a
                    href={`${baseUrlPort}/storedetail/${store.displayNameDash}`}
                    className="spStoreName"
                    target="_blank"
                    rel="noopener noreferrer"
                  >{store.display_name}</a>
                  <p className="spPrimaryTypeDisplayName">{store.primaryTypeDisplayName}</p>
                  <div className="spStoreRating">
                  {renderStars2(store.rating)}
                    <span>({store.userRatingsCount})</span>
                  </div>
                  {/* <p className="spStoreTypes">{store.types}</p> */}
                  <a
                    href={store.mapurl}
                    className="spMapUrl"
                    target="_blank"
                    rel="noopener noreferrer"
                  > <i className="fas fa-directions"></i>{(store.distanceMeters / 1000).toFixed(2)} km
                  </a>
                  <p className="spStoreAddress">
                  <i className="fas fa-map-marker-alt"></i>
                    {store.formattedAddress}</p>
             
                  <a
                    href={store.website}
                    className="spStoreWebsite"
                    target="_blank"
                    rel="noopener noreferrer"
                  ><i className="fas fa-globe"></i>{store.website}</a>
                  <p className="spStorePhone"><i className="fas fa-phone"></i> {store.formattedPhoneNumber}</p>

                </div>

                <div className="spStoreImage">
                  <img src={`${store.iconMaskBaseUri}`} alt="" style={{ backgroundColor: store.iconBackgroundColor }} />
                </div>

              </div>
            ))}
          </div>
        </div>
        <div className="spNavRow4">
          {/* {CateTypelinks.map((link, index) => (
            <a key={index} href={link.url} className='spNavCategoryLink'>
              {link.name}
            </a>
          ))} */}
        <Pagination
        totalItems={totalItems}
        itemsPerPage={itemsPerPage}
        basePath={basePath}
        curPage = {curpage}
      />
        </div>        
      </div>
      <div className={`hpBottomBanner ${isLoaded ? 'visible' : 'hidden'}`}>
        <span className="hpCopyRight">Copyright © iezloc 2024</span>
        <span className="hpContact">info@iezloc.com</span>
        <Link to="/privacy-policy" className="hpPrivacyPolicy">Privacy Policy and Disclaimer</Link>
      </div>
    </>
  );
}
function renderStars2(rating) {
  const fullStars = Math.floor(rating);
  const halfStars = Math.ceil(rating - fullStars);
  const emptyStars = 5 - fullStars - halfStars;

  return (
    <>
      {[...Array(fullStars)].map((_, i) => <i key={`full-${i}`} className="fas fa-star"></i>)}
      {[...Array(halfStars)].map((_, i) => <i key={`half-${i}`} className="fas fa-star-half-alt"></i>)}
      {[...Array(emptyStars)].map((_, i) => <i key={`empty-${i}`} className="far fa-star"></i>)}
    </>
  );
}

export default StorePage;

