import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes, Link } from 'react-router-dom';
import { NavLink } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import './HomePage.css'; // Import the CSS file
import './StoreDetailPage.css'; // Import the CSS file
import StoreCategoryType from './StoreCategoryType';
import { parsePlaceDetailPathSegments } from './PlacePageUtils'
import StorePageSummaryPlugin from './StorePageSummary'


function StoreDetailPage() {
  // const [isSortMenuVisible, setIsSortMenuVisible] = useState(false);
  const [sortOption, setSortOption] = useState('best-match');
  const baseUrl = process.env.NODE_ENV === 'production' ? 'https://iezloc.com' : 'http://localhost';
  const baseUrlPort = process.env.NODE_ENV === 'production' ? 'https://iezloc.com' : 'http://localhost:3000';;
  const [CateTypelinks, setCateTypelinks] = useState([]);
  const [CateTypeslinks, setCateTypeslinks] = useState([]);
  const [isLoaded, setIsLoaded] = useState(false);
  const [basePath, setBasePath] = useState(`${baseUrlPort}/place-detail/`);
  const [curpage, setCurpage] = useState(1);
  const[pathstorename,setPathstorename]=useState('')

  // Assuming you have a state to keep track of items
  const totalItems = 180; // for example, if you have 180 items
  const itemsPerPage = 30; // assuming 10 items per page


  const [selectedCategory, setSelectedCategory] = useState('all');
  const [selectedType, setSelectedType] = useState('all');

  const categories = ['all', ...Object.keys(StoreCategoryType)];
  const types = selectedCategory === 'all' ? [] : StoreCategoryType[selectedCategory];

  function findCategoryByKeyValue(obj, value) {
    let foundKey = null;
    // Iterate over the object
    Object.entries(obj).forEach(([key, values]) => {
      // Check if the value exists in the array
      if (values.includes(value)) {
        foundKey = key;
      }
    });
    return foundKey;
  }
  // Handle the key down event
  const handleKeyDown = (event) => {
    // Check if the Enter key was pressed
    if (event.key === 'Enter') {
      handleSearch();
    }
  };
  const handleCategoryChange = (event) => {
    const newCategory = event.target.value;
    setSelectedCategory(newCategory);
    // If 'all' is not selected, set the first type of the new category as selected
    if (newCategory !== 'all' && StoreCategoryType[newCategory].length > 0) {
      setSelectedType(StoreCategoryType[newCategory][0]);
    } else {
      setSelectedType('all');
    }
  };

  const handleTypeChange = (event) => {
    setSelectedType(event.target.value);
  };

  const [searchName, setSearchName] = useState('all');

  const handleSearchInputChange = (event) => {
    setSearchName(event.target.value || 'all');
  };



  const handleSearch = () => {
    triggerSearch(selectedType, searchName, sortOption);
  };
  const [searchResults, setSearchResults] = useState([]);

  // Function to trigger the search request
  const triggerSearch = (selectedType, searchName, sortBy) => {

    // Prepare your search parameters here
    const type = selectedType !== 'all' ? selectedType : 'all';
    const name = searchName.trim() !== '' ? searchName : 'all';
    const sortby = sortBy;
    const rating = '=-1';
    const user_ratings_total = '=-1';
    // Call handleSearch with the prepared parameters
    // const query = `${baseUrlPort}/store?type=${encodeURIComponent(type)}&name=${encodeURIComponent(name)}` +
    //   `&rating=${rating}&user_ratings_total=${user_ratings_total}` +
    //   `&sortby=${encodeURIComponent(sortby)}`;
      let query = `${baseUrlPort}/place/${encodeURIComponent(type)}?page=1`;

      if (name !== 'all') {
        query += `&name=${encodeURIComponent(name)}`;
      }

      if (sortby !== 'best-match') {
        query += `&sort=${encodeURIComponent(sortby)}`;
      }
    window.location.href = query;

  };
  // Function to perform the actual search
  const handleStoreDetailRequst = async (storename) => {

    const query = `${baseUrl}/storedetailsearch/${encodeURIComponent(storename)}`;

    try {
      const response = await fetch(query);
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      const data = await response.json();
      setSearchResults(data); // Assuming the response is an array of objects
    } catch (error) {
      console.error('Failed to fetch search results:', error);
    }
  };


  // useEffect hook to run once the component is mounted
  const setCategoryNav = () => {
    const generatedLinks = Object.entries(StoreCategoryType).map(([category, types]) => {
      const firstType = types[0];
      //const url = `${baseUrlPort}/store?type=${firstType}&name=all&rating==-1&user_ratings_total==-1&sortby=BestMatch`;
      let url = `${baseUrlPort}/place/${firstType}?page=1`;
      if (sortOption !== 'best-match') {
        url += `&sort=${sortOption}`;
      }
      return { name: category, url: url };
    });
    setCateTypelinks(generatedLinks);
  };
  // init left side full types list:
  const setCategoryTypesNav = (selectedCategory) => {

    // Start with the selected category as the first item
    const generatedLinks = [{
      name: selectedCategory,
      url: "#",  // Placeholder URL for the category
    }];
    if (selectedCategory === "all") {
      return;
    }
    const types = StoreCategoryType[selectedCategory];

    if (!types) {
      console.error(`The category '${selectedCategory}' does not exist.`);
      return;
    }

    // const generatedLinks = types.map(type => {
    generatedLinks.push(...types.map(type => {
     // const url = `${baseUrlPort}/store?type=${type}&name=all&rating==-1&user_ratings_total==-1&sortby=BestMatch`;
      let url = `${baseUrlPort}/place/${type}?page=1`;
      if (sortOption !== 'best-match') {
        url = `${baseUrlPort}/place/${type}?page=1&sort=${sortOption}`;
      }
      return { name: type, url };
    }));

    setCateTypeslinks(generatedLinks);
  };
  useEffect(() => {
    //for generate category-first-type link
    setCategoryNav();
    const { pathroute, pathstorename } = parsePlaceDetailPathSegments();
    setPathstorename(pathstorename);
    handleStoreDetailRequst(pathstorename).then(() => {
      setIsLoaded(true);
    });;

    // for loading results

  }, []); // The empty array means this effect runs only once after initial render
  // function StorePage() {
  return (
    <>
      <div className="hpTopBanner">
      <Helmet>
      <title>find best place of {pathstorename} in ottawa</title>
      <meta name="description"
      content="Discover the best places in Ottawa with our comprehensive guide.
       We offer different ranking methods by distance, rating, and reviews.
       You can also search by keywords, place name, or street."/>
      <link rel="canonical" href={`${basePath}${pathstorename}`} />
      </Helmet>
        <span className="hpLogo">Iezloc</span>
        <ul className="hpNavigationMenu">
          <li><NavLink to="/" className={({ isActive }) => (isActive ? 'activeLink' : '')}>Home</NavLink></li>
          <li><NavLink to="/place" className={({ isActive }) => (isActive ? 'activeLink' : '')} >Place</NavLink></li>
          <li><NavLink to="/about-us" className={({ isActive }) => (isActive ? 'activeLink' : '')}>About us</NavLink></li>
          <li>Contact</li>
        </ul>
      </div>
      <div className="sdpContainer">
        <div className="sdpNavRow1">
          <select
            value={selectedCategory}
            onChange={handleCategoryChange}
            className="sdpCategory"
          >
            {categories.map((category, index) => (
              <option key={index} value={category}>
                {category !== 'all' ? category : 'All'} {/* Display 'All' for the 'all' value */}
              </option>
            ))}
          </select>
          <select
            value={selectedType}
            onChange={handleTypeChange}
            className="sdpType"
            disabled={selectedCategory === 'all'} // Disable if 'All' categories are selected
          >
            {/* Render the 'All' option only if the selected category is 'all' */}
            {selectedCategory === 'all' && <option value="all">All</option>}
            {types.map((type, index) => (
              <option key={index} value={type}>
                {type}
              </option>
            ))}
          </select>
          <div className="sdpInputContainer">
            <input
              className="sdpInputWords"
              type="text"
              value={searchName === 'all' ? '' : searchName}
              onChange={handleSearchInputChange}
              onKeyDown={handleKeyDown}
              placeholder="Search address ,name"
            />
            <button className="sdpInputButton" onClick={handleSearch} tabIndex="0">Search</button>
          </div>
        </div>
        <div className="sdpNavRow2">

        </div>
        <div className="sdpNavRow3">
          {CateTypelinks.map((link, index) => (
            <a key={index} href={link.url} className="sdpNavCategoryLink">
              {link.name}
            </a>
          ))}
        </div>
        <div className="sdpTwoColGrid">
          <div className="sdpLeftNav1">
            <ul className="sdpLeftTypes">
              {CateTypeslinks.map((link, index) => (
                <li key={index} className="sdpLeftTypeslist">
                  <a className="sdpLeftTypeslink" href={link.url}>
                    {link.name}
                  </a>
                </li>
              ))}
            </ul>
          </div>

          <div className="sdpResultsGrid">
            {searchResults.map((store, index) => (
              <div className="sdpStoreGrid" key={index}>
                <div className="sdpStoreImage">
                  {/* <img src={`${store.iconMaskBaseUri}`} alt="" style={{ backgroundColor: store.iconBackgroundColor }} /> */}
                </div>
                <div className="sdpStoreInfo">
                  {/* <h2 className="sdpStoreName">{store.display_name}</h2> */}
                  <a
                    href={store.website}
                    className="sdpStoreName"
                    target="_blank"
                    rel="noopener noreferrer"
                  >{store.displayName}</a>
                  <p className="sdpPrimaryTypeDisplayName">{store.displayName}</p>
                  <p className="sdpStoreAddress">
                  <i className="fas fa-map-marker-alt"></i>Address:{store.formattedAddress}</p>
                  <p className="sdpStoreTypes">Types:{store.types}</p>
                  <div className="sdpStoreRating">
                    {renderStars(store.rating)}
                    {/* <span>Rating:{store.rating}</span> */}
                    <span>({store.userRatingCount})</span>
                  </div>
                  <a
                    href={store.website}
                    className="sdpStoreWebsite"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                  <i className="fas fa-globe"></i>Visit Website</a>
                  {/* <p className="sdpStorePhone">Tel: {store.formattedPhoneNumber}</p> */}
                  <a href={`tel:${store.formattedPhoneNumber}`} className="sdpStorePhone">
                  <i className="fas fa-phone"></i> Tel: {store.formattedPhoneNumber}
                  </a>
                  <a
                    href={store.mapUrl}
                    className="sdpMapUrl"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <i className="fas fa-directions"></i>Directions</a>
                </div>
                <StorePageSummaryPlugin urlsummary={store.urlsummary}/>

              </div>
            ))}
           
          </div>
        </div>
      </div>
      <div className={`hpBottomBanner ${isLoaded ? 'visible' : 'hidden'}`}>
        <span className="hpCopyRight">Copyright © iezloc 2024</span>
        <Link to="/privacy-policy" className="hpPrivacyPolicy">Privacy Policy</Link>
      </div>
    </>
  );
}


  function RenderDetail({ index, detail }) {
    switch (detail.statusCode) {
      case 'img':
        return (
          <div className=" sdprdetailitem">
            <img src={detail.weburl} alt={detail.rawText} title={detail.rawText} />
            {/* <p>{detail.rawText}</p> */}
          </div>
        );
      case 'a':
        return (
          // <a href={detail.weburl}>{detail.rawText}</a>
          <div className="sdprdetailitem">
            <a href={detail.weburl} target="_blank">{detail.rawText}</a>
          </div>
        );
      case 'a-phone':
        return (
          <div className="sdprdetailitem">
            <a href={`tel:${detail.weburl}`}>{detail.rawText}</a>
          </div>
        );
      case 'a-email':
        return (
          <div className="sdprdetailitem">
            <a href={`mailto:${detail.weburl}`}>{detail.rawText}</a>
          </div>
        );
      default:
        // Assuming 'status_code' for social platforms is either 'facebook', 'twitter', etc.
        return (
          <div className="sdprdetailitem">
            <a href={detail.weburl} target="_blank">
              <i className={`fab fa-${detail.statusCode}`}></i>
              {detail.rawText.length > 20 ? `${detail.rawText.substring(0, 20)}...` : detail.rawText}
            </a>
          </div>
        );
    
  }
}

function renderStars(rating) {
  const fullStars = Math.floor(rating);
  const halfStars = Math.ceil(rating - fullStars);
  const emptyStars = 5 - fullStars - halfStars;

  return (
    <>
      {[...Array(fullStars)].map((_, i) => <i key={`full-${i}`} className="fas fa-star"></i>)}
      {[...Array(halfStars)].map((_, i) => <i key={`half-${i}`} className="fas fa-star-half-alt"></i>)}
      {[...Array(emptyStars)].map((_, i) => <i key={`empty-${i}`} className="far fa-star"></i>)}
    </>
  );
}

  export default StoreDetailPage;

