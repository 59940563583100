import { useState, useEffect } from 'react';

const useLocation = (defaultLat = 45.4288655, defaultLon = -75.6911593) => {
  // Check local storage synchronously
  const savedLocation = localStorage.getItem('userLocation');
  const initialLocationData = savedLocation
    ? JSON.parse(savedLocation)
    : {
        latitude: defaultLat,
        longitude: defaultLon,
        address: defaultLat && defaultLon ? 'ByWard Market' : 'Loading location...',
      };

  const [locationData, setLocationData] = useState(initialLocationData);

  useEffect(() => {
    if (!savedLocation) {
      getLocationAndStore();
    }
  }, []);

  const getAddressFromCoordinates = async (lat, lon) => {
    const url = `https://nominatim.openstreetmap.org/reverse?format=jsonv2&lat=${lat}&lon=${lon}&addressdetails=1`;
    try {
      const response = await fetch(url);
      const data = await response.json();
      console.log('Fetched address from coordinates:', data.address);
      return data.address;
    } catch (error) {
      console.error('Error getting address:', error);
      return null;
    }
  };

  const getLocationAndStore = () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(async (position) => {
        const latitude = position.coords.latitude;
        const longitude = position.coords.longitude;
        console.log(`Fetched location: (${latitude}, ${longitude})`);

        const address = await getAddressFromCoordinates(latitude, longitude);
        if (address) {
          const formattedAddress = `${address.house_number} ${address.road}, ${address.city || address.town || address.village}, ${address.state}, ${address.postcode}`;
          const locationData = {
            latitude,
            longitude,
            address: formattedAddress,
          };
          localStorage.setItem('userLocation', JSON.stringify(locationData));
          setLocationData(locationData);
          console.log('Location data saved locally:', locationData);
        }
      }, showError);
    } else {
      console.log('Geolocation is not supported by this browser.');
    }
  };

  const showError = (error) => {
    switch (error.code) {
      case error.PERMISSION_DENIED:
        console.log('User denied the request for Geolocation.');
        break;
      case error.POSITION_UNAVAILABLE:
        console.log('Location information is unavailable.');
        break;
      case error.TIMEOUT:
        console.log('The request to get user location timed out.');
        break;
      case error.UNKNOWN_ERROR:
        console.log('An unknown error occurred.');
        break;
      default:
        console.log('An unknown error occurred.');
    }
  };

  const locationElement = locationData ? (
    <>
      <i className="fas fa-map-marker-alt" style={{ marginRight: '8px' }}></i>
      {locationData.address}
    </>
  ) : (
    'Loading location...'
  );

  return {
    locationElement,
    latitude: locationData.latitude,
    longitude: locationData.longitude,
  };
};

export default useLocation;
