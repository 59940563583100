import React, { useState, useEffect } from 'react';
import './StorePageSummary.css';

const data2 = {
  "website_url": "https://example.com",
  "emails": [
    {
      "name": "info@example.com",
      "url": "mailto:info@example.com"
    },
    {
      "name": "support@example.com",
      "url": "mailto:support@example.com"
    },
    {
      "name": "sales@example.com",
      "url": "mailto:sales@example.com"
    }
  ],
  "phone_numbers": [
    {
      "name": "123-456-7890",
      "url": "tel:123-456-7890"
    },
    {
      "name": "098-765-4321",
      "url": "tel:098-765-4321"
    },
    {
      "name": "111-222-3333",
      "url": "tel:111-222-3333"
    }
  ],
  "addresses": [
    {
      "name": "123 Example St, City, Country",
      "url": "#"
    },
    {
      "name": "456 Another St, City, Country",
      "url": "#"
    },
    {
      "name": "789 Third St, City, Country",
      "url": "#"
    }
  ],
  "working_hours": [
    {
      "name": "Monday to Friday: 09:00 – 18:00",
      "url": "#"
    },
    {
      "name": "Weekend: Closed",
      "url": "#"
    },
    {
      "name": "Monday to Friday: 10:00 – 17:00",
      "url": "#"
    },
    {
      "name": "Weekend: 10:00 – 14:00",
      "url": "#"
    }
  ],
  "social_media_links": [
    {
      "name": "facebook",
      "url": "https://facebook.com/example"
    },
    {
      "name": "instagram",
      "url": "https://instagram.com/example"
    }
  ],
  "navigation_menu": [
    {
      "name": "Home",
      "url": "https://example.com/home"
    },
    {
      "name": "About Us",
      "url": "https://example.com/about",
      "sub_menu": [
        {
          "name": "Our Team",
          "url": "https://example.com/about/team"
        },
        {
          "name": "History",
          "url": "https://example.com/about/history"
        }
      ]
    },
    {
      "name": "Services",
      "url": "https://example.com/services",
      "sub_menu": [
        {
          "name": "Consulting",
          "url": "https://example.com/services/consulting"
        },
        {
          "name": "Support",
          "url": "https://example.com/services/support"
        }
      ]
    }
  ],
  "images": [
    {
      "name": "Image 1",
      "url": "https://example.com/image1.jpg"
    },
    {
      "name": "Image 2",
      "url": "https://example.com/image2.jpg"
    }
  ]
};

// const ItemList = ({ items, isImage }) => (
//   <ul>
//     {items.map(item => (
//       <li key={item.url}>
//         {isImage ? (
//           <>
//             <h4>{item.name}</h4>
//             <img src={item.url} alt={item.name} style={{ maxWidth: '100%', height: 'auto', marginBottom: '10px' }} />
//           </>
//         ) : (
//           <a href={item.url}>{item.name.charAt(0).toUpperCase() + item.name.slice(1)}</a>
//         )}
//       </li>
//     ))}
//   </ul>
// );

// import React, { useState } from 'react';

const ItemList = ({ items=[], isImage }) => {
  return (
    <ul>
      {items.map((item, index) => (
        <Item key={index} item={item} isImage={isImage} />
      ))}
    </ul>
  );
};

const Item = ({ item, isImage }) => {
  const [isOpen, setIsOpen] = useState(item.sub_menu ? true : false);

  const toggleSubMenu = () => {
    setIsOpen(!isOpen);
  };

  return (
    <li>
      {isImage ? (
        <>
          <h4>{item.name}</h4>
          <img src={item.url} alt={item.name} style={{ maxWidth: '100%', height: 'auto', marginBottom: '10px' }} />
        </>
      ) : (
        <a href={item.url} onClick={item.sub_menu? (e) => { e.preventDefault(); toggleSubMenu(); } : null}>
          {item.name}
        </a>
      )}
      {item.sub_menu && isOpen && (
          <SubMenu items={item.sub_menu} />
        // <ul>
        //   {item.sub_menu.map((subItem, index) => (
        //     <li key={index}>
        //       <a href={subItem.url}>{subItem.name}</a>
        //     </li>
        //   ))}
        // </ul>
      )}
    </li>
  );
};

const SubMenu = ({ items }) => {
  return (
    <ul>
      {items.map((subItem, index) => (
        <SubMenuItem key={index} item={subItem} />
      ))}
    </ul>
  );
};

const SubMenuItem = ({ item }) => {
  const [isOpen, setIsOpen] = useState(item.sub_menu ? true : false);

  const toggleSubMenu = () => {
    setIsOpen(!isOpen);
  };

  return (
    <li>
      <a href={item.url} onClick={item.sub_menu ? (e) => { e.preventDefault(); toggleSubMenu(); } : null}>
        {item.name}
      </a>
      {item.sub_menu && isOpen && (
        <ul>
          {item.sub_menu.map((subSubItem, index) => (
            <li key={index}>
              <a href={subSubItem.url}>{subSubItem.name}</a>
            </li>
          ))}
        </ul>
      )}
    </li>
  );
};
// export default ItemList;


const StorePageSummaryPlugin = ({ urlsummary }) => {
  const [data, setData] = useState(null);

  useEffect(() => {
    if (urlsummary) {
    try {
      // console.log('urlsummaryg', urlsummary);
      const jsonData = JSON.parse(urlsummary);
      setData(jsonData);
    } catch (error) {
      console.error('Invalid JSON string', error);
    }
  }
}, [urlsummary]);

  if (!data) {
    return <div>Loading...</div>;
  }
  return (
    <div className="spscontainer">
      <div className="spscategory">
        <h2>WEBSITE URL</h2>
        <a href={data.website_url}>{data.website_url}</a>
      </div>
      <div className="spscategory">
        <h2>EMAILS</h2>
        <ItemList items={data.emails} />
      </div>
      <div className="spscategory">
        <h2>PHONE NUMBERS</h2>
        <ItemList items={data.phone_numbers} />
      </div>
      <div className="spscategory">
        <h2>ADDRESSES</h2>
        <ItemList items={data.addresses} />
      </div>
      <div className="spscategory">
        <h2>WORKING HOURS</h2>
        <ItemList items={data.working_hours} />
      </div>
      <div className="spscategory">
        <h2>SOCIAL MEDIA LINKS</h2>
        <ItemList items={data.social_media_links} />
      </div>
      <div className="spscategory">
        <h2>NAVIGATION MENU</h2>
        <ItemList items={data.navigation_menu} />
      </div>
      <div className="spscategory">
        <h2>IMAGES</h2>
        <ItemList items={data.images} isImage={true} />
      </div>
    </div>
  );
};

export default StorePageSummaryPlugin;
