// utils.js or a similar utility file
// urlUtils.js
// export const parseUrlParams = () => {
//     const params = new URLSearchParams(window.location.search);
//     const para_type = params.get('type') || 'all';
//     const para_name = params.get('name') || 'all';
//     const para_rating = params.get('rating') || '=-1';
//     const para_reviews = params.get('user_ratings_total') || '=-1';
//     const para_sort_by = params.get('sortby') || 'BestMatch';
//     const para_page = params.get('page') ||1;
  
//     return {para_type,para_name,para_rating, para_reviews, para_sort_by,para_page};
//   };


  const parsePathParams = (path) => {
    const segments = path.split('/').filter(Boolean); // Split path and filter out empty segments
    let para_type = 'all';

  
    if (segments.length >= 2 && segments[0] === 'place') {
      para_type = segments[1] || 'all';
      // if (segments.length > 2 && segments[2].startsWith('page-')) {
      //   para_page = parseInt(segments[2].split('-')[1], 10) || 1;
      // }
    }
  
    return { para_type };
  };
  
  const parseQueryParams = (search) => {
    const params = new URLSearchParams(search);
    const para_name = params.get('name') || 'all';
    const para_rating = params.get('rating') || '=-1';// not using 
    const para_reviews = params.get('review') || '=-1'; // not using 
    const para_sort_by = params.get('sort') || 'best-match';
    const para_page = params.get('page') ||1;
    return { para_name, para_rating, para_reviews, para_sort_by,para_page};
    
  };
  
  export const parsePlaceUrlParams = () => {
    const { pathname, search } = window.location;
    const { para_type } = parsePathParams(pathname);
    const { para_name, para_rating, para_reviews, para_sort_by,para_page } = parseQueryParams(search);
  
    return { para_type, para_name, para_rating, para_reviews, para_sort_by, para_page };
  };



  export const parsePlaceDetailPathSegments = () => {
    const path = window.location.pathname;
    const segments = path.split('/').filter(Boolean); // Split path by '/' and remove empty segments
    const pathroute = segments.length >0 ? segments[0] :'place-detail'
    const pathstorename = segments.length >1 ? segments[1] :'all'
  
    return {pathroute,pathstorename};
  };
  

  const parseOfferingPathParams = (path) => {
    const segments = path.split('/').filter(Boolean); // Split path and filter out empty segments
    let para_type = 'all';

  
    if (segments.length >= 2 && segments[0] === 'offering') {
      para_type = segments[1] || 'all';
      // if (segments.length > 2 && segments[2].startsWith('page-')) {
      //   para_page = parseInt(segments[2].split('-')[1], 10) || 1;
      // }
    }
  
    return { para_type };
  };
  export const parseOfferingPpUrlParams = () => {
    const { pathname, search } = window.location;
    const { para_type } = parseOfferingPathParams(pathname);

    const params = new URLSearchParams(window.location.search);

    const para_name = params.get('name') || 'all';
    const para_rating = params.get('rating') || '=-1';
    const para_reviews = params.get('user-ratings-total') || '=-1';
    const para_sort_by = params.get('sort') || 'best-match';
    const para_price_opt = params.get('priceopt') || 'all';
    const para_page = params.get('page') ||1;
    return {para_type,para_name,para_rating, para_reviews,para_price_opt,para_sort_by,para_page};
  };
  


  const parseDealPathParams = (path) => {
    const segments = path.split('/').filter(Boolean); // Split path and filter out empty segments
    let para_type = 'all';

  
    if (segments.length >= 2 && segments[0] === 'deal') {
      para_type = segments[1] || 'all';
      // if (segments.length > 2 && segments[2].startsWith('page-')) {
      //   para_page = parseInt(segments[2].split('-')[1], 10) || 1;
      // }
    }
  
    return { para_type };
  };
  export const parseDealPpUrlParams = () => {
    const { pathname, search } = window.location;
    const { para_type } = parseDealPathParams(pathname);

    const params = new URLSearchParams(window.location.search);

    const para_name = params.get('name') || 'all';
    const para_rating = params.get('rating') || '=-1';
    const para_reviews = params.get('user-ratings-total') || '=-1';
    const para_sort_by = params.get('sort') || 'best-match';
    const para_price_opt = params.get('priceopt') || 'all';
    const para_page = params.get('page') ||1;
    return {para_type,para_name,para_rating, para_reviews,para_price_opt,para_sort_by,para_page};
  };
  