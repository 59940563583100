import React from 'react';
import { BrowserRouter as Router,  Route, Routes,Navigate } from 'react-router-dom';
import HomePage from './HomePage';
import FlyerPage from './FlyerPage';
import DealPage from './DealPage';
import ProductPage from './ProductPage';
import OfferingPage  from './OfferingPage';
import StorePage from './StorePage';
import PlacePage from './PlacePage';
import StoreDetailPage from './StoreDetailPage';
import PlaceDetailPage from './PlaceDetailPage';
import OfferingDetailPage from './OfferingDetailPage';

import ProductDetailPage from './ProductDetailPage';
import PrivacyPolicy from './PrivacyPolicy'; // Import your PrivacyPolicy component
import AboutUs from './AboutUs'; // Import your PrivacyPolicy component
import NotFoundPage from './NotFoundPage'; // Import your PrivacyPolicy component
function App2() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<HomePage />} /> 
        <Route path="/place/*" element={<PlacePage />} />
        <Route path="/place-detail/*" element={<PlaceDetailPage />} />
        <Route path="/privacy-policy" element={<PrivacyPolicy />} />
        <Route path="/about-us" element={<AboutUs />} />
        <Route path="*"  element={<NotFoundPage />} />
      </Routes>
    </Router>
  );
}
export default App2;
