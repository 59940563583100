// src/CategorySelect.js


export const categoryIcons = {
  Automotive: 'fas fa-car',
  Business: 'fas fa-briefcase',
  Culture: 'fas fa-palette',
  Education: 'fas fa-school',
  Entertainment_and_Recreation: 'fas fa-film',
  Finance: 'fas fa-dollar-sign',
  Food_and_Drink: 'fas fa-utensils',
  Government: 'fas fa-building',
  Health_and_Wellness: 'fas fa-heartbeat',
  Lodging: 'fas fa-hotel',
  Places_of_Worship: 'fas fa-church',
  Services: 'fas fa-tools',
  Shopping: 'fas fa-shopping-cart',
  Sports: 'fas fa-basketball-ball',
  Transportation: 'fas fa-bus',
  all: 'fas fa-list', // Icon for the 'all' option
};

