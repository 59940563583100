import React from 'react';
import Select from 'react-select';
import { categoryIcons } from './CategoryIcons';

const CategorySelect = ({ selectedCategory, handleCategoryChange, categories,cateClassname }) => {
 

  const options = categories.map(category => ({
    value: category,
    label: (
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <i className={categoryIcons[category]} style={{ marginRight: '8px' }}></i> 
        {category !== 'all' ? category : 'All'}
      </div>
    ),
  }));

  const customStyles = {
    control: (provided) => ({
      ...provided,
      display: 'flex',
    }),
    option: (provided) => ({
      ...provided,
      display: 'flex',
      alignItems: 'center',
    }),
  };

  return (
    <Select
      value={options.find(option => option.value === selectedCategory)}
      onChange={option => handleCategoryChange({ target: { value: option.value } })}
      options={options}
      styles={customStyles}
      className={cateClassname}
    //   "spCategory"
    />
  );
};

export default CategorySelect;
